import { RouteProp } from "@react-navigation/core";
import { StackNavigationProp } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { useState, ReactNode, useMemo, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Platform, StyleSheet, View } from "react-native";

import useLastScannedLicensePlate from "./hooks/useLastScannedLicensePlate";
import useSpotAvailability from "./hooks/useSpotAvailability";
import useVehicleUpdater from "./hooks/useVehicleUpdater";
import { Page, SpotClosing, VehicleForm } from "../../components";
import {
  currencyToDisplayString,
  reportError,
  translatedError,
} from "../../helpers";
import { PublicStackParamList } from "../../routes/public";
import { Bookings, Spot, useStore, withAuthentication } from "../../stores";
import { sanitizeLicense } from "../../stores/parking/vehicle/vehicles";
import {
  Button,
  Margins,
  Title,
  ListItem,
  Text,
  Colors,
} from "../../storybook";

type StartNavigationProp = StackNavigationProp<
  PublicStackParamList,
  "PublicStart"
>;
type StartRouteProp = RouteProp<PublicStackParamList, "PublicStart">;

interface Props {
  navigation: StartNavigationProp;
  route: StartRouteProp;
}

const ScannedStart = (props: Props) => {
  const { route, navigation } = props;
  const spotId = route.params?.spotId;
  const { t } = useTranslation();
  const store = useStore();
  const { auth, i18n } = store;

  const spot = useMemo(() => new Spot(`spots/${spotId}`, {}, store), [spotId]);

  const { vehicle, updateVehicle, setForceUpdate } =
    useLastScannedLicensePlate(spotId);
  const { availability, closingAvailability } = useSpotAvailability(spot);
  const { getVehicle } = useVehicleUpdater();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const [vehicleDescription, setVehicleDescription] = useState<
    string | undefined
  >("");
  const { hourly } = spot.rates;

  useEffect(() => {
    if (error) {
      setTimeout(() => setError(undefined), 3000);
    }
  }, [error]);

  const create = async () => {
    if (!vehicle || !vehicle.code || !vehicle.country) return;
    setLoading(true);

    try {
      if (auth.user?.id) {
        const bookings = new Bookings(
          { userId: auth.user.id, payment: "failed" },
          store
        );
        const failed = await bookings.fetch();
        if (failed.docs.length > 0) {
          throw new Error("You have open payments");
        }
      }

      const licenseRef = sanitizeLicense(vehicle.code);
      const bookings = new Bookings(
        { spotId, "vehicle.licenseRef": licenseRef },
        store
      );
      await bookings.fetch();

      let newBooking = bookings.hasDocs ? bookings.docs[0] : undefined;

      if (!newBooking) {
        const userId = auth.user?.id;
        if (!userId) return;
        const vehicleDoc = await getVehicle(vehicle);
        if (!vehicleDoc?.id) throw new Error("Failed to create vehicle");

        let endAt;
        if (availability && !availability.open) {
          endAt = availability.endAt;
        }

        const response = await bookings.request({
          endAt,
          spot,
          vehicleIds: [vehicleDoc.id],
        });
        newBooking = response.booking;
        console.info(`New booking: ${newBooking?.id}`);
      } else {
        console.info(`Active booking: ${newBooking.id}`);
      }

      await newBooking?.openGate("entrance");
      const bookingId = newBooking?.id || "";

      if (Platform.OS === "web") {
        window.location.href = `/public/started/${bookingId}?goBack=true`;
      } else {
        navigation.navigate("PublicStarted", { bookingId, goBack: true });
      }
    } catch (err) {
      const { message } = err as Error;
      console.error("Error: ", message);
      reportError(err as Error, { name: "public/scanned-start", type: "web" });
      setError(translatedError(message));
    } finally {
      setLoading(false);
    }
  };

  const updateLanguage = (language: string) => {
    const dataForLanguage = (lang: string) => {
      switch (lang.toLowerCase()) {
        case "nl":
          return "nl-NL";
        case "fr":
          return "fr";
        case "de":
          return "de";
        default:
          return "en-GB";
      }
    };

    i18n.changeLocale(dataForLanguage(language));
  };

  const displayHourlyRates = currencyToDisplayString(
    hourly.currency,
    hourly.value,
    true
  );
  const displayDailyRates = currencyToDisplayString(
    hourly.currency,
    hourly.daily ?? 0,
    true
  );
  const licensePlateValid = (vehicle?.code?.length || 0) > 0;
  const isClosed =
    closingAvailability &&
    closingAvailability.startAt.getTime() ===
      closingAvailability.endAt.getTime();

  return (
    <Page spot={spot} tabletSizeScreen>
      <View style={styles.content}>
        <Title level={2} style={styles.title}>
          <Trans>Welcome to</Trans> {spot.name}
        </Title>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Title style={{ marginBottom: Margins.tiny }}>
            <Trans>Scanned license plate</Trans>
          </Title>
          <Title style={{ marginBottom: Margins.tiny, color: Colors.red }}>
            <Trans>Press a character to edit</Trans>
          </Title>
        </View>
        <View style={[styles.row, { justifyContent: "center" }]}>
          <VehicleForm
            value={vehicle}
            onChange={(vehicle) => {
              updateVehicle(vehicle);
              setVehicleDescription(vehicle.vehicle);

              // update language
              if (vehicle.country) updateLanguage(vehicle.country);
            }}
            huge
          />
        </View>
        <View style={styles.updateButtonsContainer}>
          <Button
            type="outline"
            title={t("Scan license plate again")}
            centre="video"
            size="small"
            onPress={() => setForceUpdate(true)}
          />
          {licensePlateValid && (
            <Button
              type="transparentRed"
              title={t("Reset")}
              centre="rotateCcw"
              size="small"
              onPress={() => updateVehicle({ code: "", country: "NL" })}
            />
          )}
        </View>
        <Button
          title={error || t("Start parking")}
          type={error ? "red" : undefined}
          centre="play"
          style={[
            styles.item,
            { alignSelf: "center", width: "100%", maxWidth: 400 },
          ]}
          size="large"
          disabled={!licensePlateValid || loading || isClosed}
          onPress={create}
          loading={loading}
        />
        <View style={styles.vehicleFound}>
          <ListItem
            style={styles.item}
            title={t("Found vehicle")}
            description={
              vehicleDescription ? vehicleDescription : t("Vehicle not found")
            }
          />
          <View style={[styles.item, { flexDirection: "column" }]}>
            <ListItem title={t("How to pay")} />
            <Text>
              <Trans>
                When you want to leave the parking lot, you can pay using the My
                Way app, the payment QR codes on-site, or the tablet at the
                reception.
              </Trans>
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={[styles.item, { flexDirection: "column" }]}>
            <ListItem title={t("Hourly rate and daily price")} />
            <View style={{ flexDirection: "column" }}>
              <Text>{`${displayHourlyRates} / ${t("hour")}`}</Text>
              {hourly.daily && (
                <Text>{`${displayDailyRates} / 24 ${t("hour")}`}</Text>
              )}
            </View>
          </View>
          <View style={[styles.item, { flexDirection: "column" }]}>
            <ListItem title={t("Instructions")} />
            <Text>
              <Trans>
                Your license plate should be automatically scanned and
                pre-filled on the screen. If not, you can manually enter the
                license plate by tapping the license plate field.
              </Trans>
            </Text>
          </View>
        </View>
        <SpotClosing availability={closingAvailability} spot={spot} />
      </View>
    </Page>
  );
};

export default withAuthentication(observer(ScannedStart));

const styles = StyleSheet.create({
  content: {
    paddingVertical: Margins.regular,
  },
  title: {
    marginBottom: Margins.small,
    textAlign: "center",
    paddingBottom: 16,
  },
  item: {
    flex: 1,
    marginVertical: Margins.small,
  },
  row: {
    flexDirection: "row",
    gap: Margins.small,
  },
  vehicleFound: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 40,
  },
  updateButtons: {
    alignSelf: "flex-start",
    padding: 10,
    borderColor: Colors.black,
    borderRadius: 8,
    borderWidth: 1,
  },
  updateButtonsContainer: {
    paddingVertical: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
